<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div style="display: flex">
        <div class="principle-dd">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Pillar</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="pillarName" disabled>
          </b-form-input>
        </div>
        <div class="principle-dd" style="padding-left: 0.5rem">
          <label for="pillar" class="form-label page-heading-dropdown lable-text">Principle</label>
          <b-form-input class="form-control select-text select-text-pd text-class" v-model="principleName"
            disabled></b-form-input>
        </div>
      </div>

      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0"
              :class="{
                cursorNotAllowed: targetLevel != 0,
                'active-level': targetLevel == 0,
              }" :disabled="targetLevel != 0">
              <label class="container">
                Level 0
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1" :class="{
              cursorNotAllowed: targetLevel != 1,
              'active-level': targetLevel == 1,
            }" :disabled="targetLevel != 1">
              <label class="container">
                Level 1
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2" :class="{
              cursorNotAllowed: targetLevel != 2,
              'active-level': targetLevel == 2,
            }" :disabled="targetLevel != 2">
              <label class="container">
                Level 2
                <input id="level2" type="radio" class="cursorpointer" name="some-radios" value="2"
                  style="margin-right: 1rem" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3" :class="{
              cursorNotAllowed: targetLevel != 3,
              'active-level': targetLevel == 3,
            }" :disabled="targetLevel != 3">
              <label class="container">
                Level 3
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4" :class="{
              cursorNotAllowed: targetLevel != 4,
              'active-level': targetLevel == 4,
            }" :disabled="targetLevel != 4">
              <label class="container">
                Level 4
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" :class="{
              cursorNotAllowed: targetLevel != 5,
              'active-level': targetLevel == 5,
            }" :disabled="targetLevel != 5" style="border-radius: 0 4px 4px 0">
              <label class="container">
                Level 5
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem" />
              </label>

            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info flex-block">
        <div class="current-practice half-section">
          <div class="module-title">Current Practice</div>
          <div style="margin-top: 1rem" class="styled-input">
            <textarea class="page-content-regular action-desc" id="practiceDescription" name="practiceDescription"
              rows="3" disabled cols="105" ref="practiceDescription" v-model="practiceDescription"></textarea>
            <label for="practiceDescription" class="form-label page-heading-dropdown lable-text comment-lable">
              Practice Description
            </label>
          </div>
          <div style="margin-top: 1rem" class="styled-input">
            <textarea class="page-content-regular action-desc" id="guidelineDesc" name="guidelineDesc" rows="3" disabled
              cols="105" ref="guidelineDesc" v-model="guidelineDesc"></textarea>
            <label for="guidelineDesc" class="form-label page-heading-dropdown lable-text comment-lable">
              Guidelines
            </label>
          </div>
        </div>
        <div class="middle-line"></div>
        <div class="half-section">
          <div class="proposed-practice">
            <div class="module-title">Proposed Practice</div>
          </div>
          <div style="margin-top: 1rem" class="styled-input">
            <textarea class="page-content-regular action-desc" id="newPracticeDesc" name="newPracticeDesc" rows="3"
              cols="105" ref="newPracticeDesc" v-model="newPracticeDesc" disabled></textarea>
            <label for="newPracticeDesc" class="form-label page-heading-dropdown lable-text comment-lable">
              Practice Description
            </label>
          </div>
          <div style="margin-top: 1rem" class="styled-input">
            <textarea class="page-content-regular action-desc" id="newGuidelines" name="newGuidelines" rows="3"
              cols="105" ref="newGuidelines" v-model="newGuidelines" disabled></textarea>
            <label for="newGuidelines" class="form-label page-heading-dropdown lable-text comment-lable">
              Guidelines
            </label>
          </div>
        </div>
      </div>
      <div class="attch-con">
        <div class="feedback-con" :class="{ 'edit-con': reviewRequest == 1 }">
          <div class="module-title prp-name margin-top-1em" style="padding-top: 1rem">
            Recommendations
          </div>
          <div class="recommend-d" style="display: flex">
            <div class="col-md-12" style="padding-left: 0px" v-if="reviewRequest == 1">
              <label for="pillar" class="form-label select-lable lable-text">Review Decision<span
                  class="req">*</span></label>
              <select class="
                  units
                  form-control
                  page-heading-dropdown-content
                  principal-selecct
                  cust-st-icon
                " id="recommend" v-model="reviewDecision" required>
                <option v-for="change in reviewDecisionList" v-bind:key="change.typeId" :value="change.typeId">
                  {{ change.typeDescription }}
                </option>
              </select>
            </div>
            <div class="col-md-12" style="padding-left: 0px" v-else>
              <label for="pillar" class="form-label select-lable lable-text1">Review Decision</label>
              <b-form-input class="form-control select-text select-text-pd text-class" id="changeType" disabled
                v-model="reviewDecisionLable" style="background: transparent; border: 1px solid #313131">
              </b-form-input>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 padding-input">
            <label for="pillar" class="form-label select-lable"
              :class="reviewRequest == 1 ? 'lable-text' : 'lable-text1'">Feedback
              <span v-if="reviewRequest == 1 && reviewDecision == 12" class="req">*</span></label>
            <textarea class="form-control select-text text-col input-border"
              :class="{ 'text-class': reviewRequest == 2 }" id="proposedPractice" :disabled="reviewRequest == 2"
              v-model="feedback">
            </textarea>
          </div>
        </div>
        <div class="mat-info-con add-action-footer-info border-top" v-if="reviewRequest == 2">
          <b-button pill class="primar-btn submit-btn" size="lg" @click="discardEdit()">
            Close
          </b-button>

        </div>
        <div class="mat-info-con add-action-footer-info border-top" v-else>
          <b-button class="primar-btn discard-btn" size="lg" @click="discardEdit()">
            Discard
          </b-button>
          <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="saveDetails()">
            Submit
          </b-button>
        </div>
      </div>
    </div>
    <MessagePopup class="alert" :routToBack="true" :message="errorMsg" :show="showPopup" :headText="headText"
      :reqRaise="reqRaise" />
  </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Role from "../../../common/constants/roles";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
import { mapGetters } from "vuex";
import MessagePopup from "@/components/common/MessagePopup.vue";
export default {
  name: "RequestValidation",
  components: {
    Breadcrumb,
    Loader,
    MessagePopup,
  },
  data() {
    return {
      plantId: "",
      pillarName: "",
      roles: Role,
      practiceId: "",
      targetLevel: "",
      principleName: "",
      requestId: "",
      reviewDecision: "",
      feedback: "",
      errorMsg: "",
      reqRaise: "",
      showPopup: false,
      headText: "",
      reviewDecisionList: [],
      reviewDecisionLable: "",
      newGuidelines: "",
      newPracticeDesc: "",
      guidelineDesc: "",
      practiceDescription: "",
      showLoader: false,
    };
  },
  beforeCreate() { },
  computed: {
    ...mapGetters(["reviewRequest"]),
    ...mapGetters(["validationReqFeedback"]),
  },
  created() {
    this.showLoader = true
    this.plantId = this.$route.params.plantId;
    this.targetLevel = this.$route.params.levelId;
    this.pillarName = this.$route.params.pillarName;
    this.practiceId = this.$route.params.principleId;
    this.principleName = this.$route.params.principleName;
    this.requestId = this.$route.params.requestId;

    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    let title = this.reviewRequest == 1 ? "Validate Practice" : "View Practice"
    this.$store.dispatch("breadcrumb", {
      title: title,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Governance",
          redirect: "/governance",
          primary: true,
        },
        // { name: "Assessment", primary: false },
      ],
    });
    if (this.reviewRequest == 1)
      this.getReviewDecisionTypes();
    else {
      this.feedback = this.validationReqFeedback.feedback;
      this.reviewDecisionLable = this.validationReqFeedback.reviewDecision;

      console.log(this.reviewRequest);
    }
    this.getPractiesDetails();
  },

  methods: {
    discardEdit() {
      this.$router.push("/governance");
    },
    getViewRequest() {
      ApiCalls.httpCallDetails(Api.GETVIEWREQUEST + this.requestId, "get").then(
        (data) => {
          if (this.$route.params.edit == 1) {
            this.changeTypeMod = data.data.changeType;
            this.proposedPractice = data.data.proposedPractice;
            this.reasonForChange = data.data.reasonForChange;
          } else {
            console.log("get view data ", data);
            this.plants = data.data.plant;
            document.getElementById("proposedPractice").value = data.data
              .proposedPractice
              ? data.data.proposedPractice
              : "";
            document.getElementById("reason").value = data.data.reasonForChange
              ? data.data.reasonForChange
              : "";
            document.getElementById("changeType").value = data.data.changeType
              ? data.data.changeType
              : "";
            if (
              data.data.submittedBy !== "" &&
              data.data.submittedBy !== null &&
              data.data.submittedBy !== undefined
            ) {
              this.submitBy = data.data.submittedBy;
            }
            if (
              data.data.plant !== "" &&
              data.data.plant !== null &&
              data.data.plant !== undefined
            ) {
              this.plants = data.data.plant;
            }
            this.changeTypeMod = data.data.changeType;
            this.proposedPractice = data.data.proposedPractice;
            this.reasonForChange = data.data.reasonForChange;
          }
        }
      );
    },
    getReviewDecisionTypes() {
      ApiCalls.httpCallDetails(Api.REVIEWDECISION, "get").then((data) => {
        this.reviewDecisionList = data.data;
      });
    },
    getPractiesDetails() {
      this.showLoader = true;
      ApiCalls.httpCallDetails(Api.PLANTPOVALIDATIONPROPOSED + this.requestId + "/" + this.practiceId, "get").then((data) => {
        this.showLoader = false;
        this.newGuidelines = data.data.proposedPractice ? data.data.proposedPractice.practiceGuidelines : '';
        this.newPracticeDesc = data.data.proposedPractice ? data.data.proposedPractice.practiceDescription : '';
        this.guidelineDesc = data.data.originalPractice ? data.data.originalPractice.practiceGuidelines : '';
        this.practiceDescription = data.data.originalPractice ? data.data.originalPractice.practiceDescription : '';
        this.showLoader = false
      });
    },
    saveDetails() {
      if (this.checkRequiredFields())
        return;
      this.showLoader = true

      let requestPayload = {
        reviewDecisionId: this.reviewDecision,
        feedback: this.feedback
      };
      console.log(requestPayload);
      ApiCalls.httpCallDetails(
        Api.PLATVALIDATIONSUBMIT + this.requestId,
        "put",
        requestPayload
      ).then((data) => {
        this.showLoader = false;
        if (data.success) {
          setTimeout(() => {
            this.showLoader = false

            this.$router.go(-1);
          }, 1000);
        }
      });
    },
    checkRequiredFields() {
      if (!this.reviewDecision) {
        this.showPopup = true;
        this.errorMsg = "Please select Review Decision";
        this.headText = "";
        return true;
      } else if (!this.feedback && this.reviewDecision == 12) {
        this.showPopup = true;
        this.errorMsg = "You must enter your concern on the proposed change in the feedback box.";
        this.headText = "";
        return true;
      }
      this.showPopup = false;
      return false
    },
  },
};
</script>

<style scoped>
.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

/* .uploaded-file-name {
  font: 1.166rem "BarlowM";
  color: #274ba7;
  margin-right: 16px;
} */
.cursorNotAllowed {
  cursor: not-allowed;
  opacity: 0.3;
}

.proposed-rec {
  background: #f9f9f9;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.add-action-footer-info {
  padding-top: 1rem;
  background: #fff;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.req {
  font-size: 1rem;
  color: red;
}

.text-col {
  height: 6rem;
}

.padding-input {
  /* padding-top: 2rem; */
  padding-left: 0rem;
  padding-bottom: 2rem;
}

.text-class {
  background-color: #f9f9f9 !important;
  border: 1px solid #d5d2d2;
  border: 1px solid #313131;
  color: #313131;
  /* padding-bottom: 2rem; */
}

textarea {
  resize: none;
}

.marginRight16Percentage {
  margin-right: 5%;
}

.col-5 {
  max-width: 55%;
}

.width100 {
  width: 100%;
}

.marginTop-minus-2 {
  margin-top: -2% !important;
}

.marginBottom-2 {
  margin-bottom: 2% !important;
}

.lable-text {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #fff;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
  z-index: 2;
  color: #313131;
}

.lable-text1 {
  position: absolute;
  /* background: rgb(243, 240, 240); */
  background: #f9f9f9;
  margin: -1.2rem 0 0 1rem;
  padding-right: 5px;
  padding-left: 5px;
}

.calender-input {
  height: 52px;
  width: 59.25rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

@media only screen and (max-width: 1365px) {
  .calender-input {
    height: 52px;
    width: 39.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1380px) {
  .calender-input {
    height: 52px;
    width: 43.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1381px) {
  .calender-input {
    height: 52px;
    width: 55.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
  .calender-input {
    height: 52px;
    width: 64.75rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

@media only screen and (min-width: 1921px) {
  .calender-input {
    height: 52px;
    width: 68.25rem;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
  }
}

.other-block {
  /* display: grid;
  justify-content: end; */
  margin-top: 2rem;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 5px;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#editButton:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.top-card {
  width: 7rem;
}

.plant-card {
  background: #fffce3;
  /* // height: 54px; */
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.action_label {
  font-family: "BarlowM", sans-serif;
  line-height: 1.5rem;
  font-size: 1.3333333333333333rem;
}

.btn-action {
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.asmt-footer-info {
  border-top: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
}

.principle-dd {
  width: 30%;
  padding: 2rem 2rem;
  padding-bottom: 0;
}

.recommend-d {
  width: 30%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.principal-selecct {
  height: 54px;
  border-color: #313131;
}

.level-con {
  padding: 1em;
  margin-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  background: #00428f;
  /* background: #006400;6577AC */
  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 57%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1rem;
}

.behavoir-des {
  margin-top: 3px;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
  list-style: none;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}


.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.shortHeight {
  height: 12rem;
}

.pagination-con {
  font-size: 0.875em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }

  .pagination-align {
    justify-content: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.8rem;
  left: 1rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0 4px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}


.styled-input.wide {
  width: 100%;
}

.review-box {
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
  font-family: "BarlowR", sans-serif;
  border-radius: 6px;
  height: 5em;
  resize: none;
}

.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}



.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.back-btn {
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.6rem 1.2rem;
}

.back-btn :hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.back-btn :active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
  margin-right: 15px;
}

.take-assessment-page-heading-tab-bottom-padding {
  padding-bottom: 1rem;
}

.take-assessment-page-heading-tab-font-weight {
  font-size: 1.083rem;
  font-family: "BarlowM", sans-serif;
}

.status-con {
  display: flex;
}

.status-col-1 {
  width: 50%;
}

.status-col-2 {
  width: 50%;
  text-align: right;
}

.status-btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.status-btn-2 {
  margin-left: 1rem;
}

.file-con {
  width: 100%;
}

#errorForComment,
#errorForUploadFile {
  color: red;
  font-family: "BarlowR", sans-serif;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.backBtnBlock {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1240px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 665px) {
  .status-con[data-v-0d8de0d6] {
    display: block !important;
  }

  .status-col-2[data-v-0d8de0d6] {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 388px) {
  .status-btn-2 {
    margin-left: 0rem;
    margin-top: 1rem !important;
  }
}



.edit-con {
  background: #fff;
}

.input-border {
  border: 1px solid black;
}

.feedback-con {
  border-top: 1px solid #e5e5e5;
}

.flex-block {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.each-plant-block {
  width: 33.3%;
  padding: 1rem 1rem 1rem 0;
}

.action-desc {
  border: #313131 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.half-section {
  width: 45%;
}

.middle-line {
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
}



.comment-lable {
  color: #313131 !important;
  top: 0 !important;
  left: 0 !important;
  background: #fff !important;
  padding: 0 4px !important;
  font-size: 1rem !important;
  font-family: "BarlowM", sans-serif !important;
  margin: -9px 1rem !important;
}

.styled-input {
  position: relative;
}

.styled-input label {
  padding: 1rem 2px;
  position: absolute;
  top: 0;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
  margin: 0 1rem;
  background: transparent;
}



@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}
</style>